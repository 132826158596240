import {useEffect, useState} from "react";

import pocketdex from "../api/pokemon-tcg-pocket.json";
import IFilter from "../interfaces/IFilter";
import IQuest from "../interfaces/IQuest";
import IPlayerCards from "../interfaces/IPlayerCards";

export const ACTUAL_EXTENSION = "puissance-genetique";
const Filters = (props: { playerCards: IPlayerCards[], onFilterChange: (event: IFilter) => void }) => {
  const boosterFilters = ["Dracaufeu", "Mewtwo", "Pikachu"];
  const rarityFilters = ["1-diamant", "2-diamant", "3-diamant", "4-diamant", "1-etoile", "2-etoile", "3-etoile", "1-couronne"];

  const [filter, setFilter] = useState<IFilter>({});
  const [quests, setQuests] = useState<IQuest[]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const loadData = () => {
      // Loading hidden quests
      const loadedQuests = pocketdex.quests.map(it => it);
      setQuests(loadedQuests);
    };

    loadData();
  }, []);

  useEffect(() => {
    setLoaded(true);
  }, [quests]);

  const changeFilter = (f: string, type: string) => {
    const updatedFilter: IFilter = structuredClone(filter);
    if (f === "reset" && type === "reset") {
      setFilter({});
      props.onFilterChange({});
      return;
    }

    if (type === "booster") {
      if (updatedFilter.boosters?.includes(f)) {
        updatedFilter.boosters = updatedFilter.boosters?.filter((b) => b !== f) || [];
      } else {
        updatedFilter.boosters = [...updatedFilter.boosters ?? [], f];
      }
    }

    if (type === "rarity") {
      if (updatedFilter.rarities?.includes(f)) {
        updatedFilter.rarities = updatedFilter.rarities?.filter((r) => r !== f) || [];
      } else {
        updatedFilter.rarities = [...updatedFilter.rarities ?? [], f];
      }
    }

    if (type === "extension") {
      updatedFilter.extension = f;
      updatedFilter.quest = undefined;
    }

    if (type === "generation") updatedFilter.generation = Number.parseInt(f);

    if (type === "quest") {
      const quest = f.split("|")[0];
      const extension = f.split("|")[1];

      if (updatedFilter.quest === quest) {
        updatedFilter.quest = undefined;
      } else {
        updatedFilter.quest = quest;
        updatedFilter.extension = extension;
      }
    }

    if (type === "obtained") {
      const b = Boolean(f)
      if (updatedFilter.obtained !== undefined && updatedFilter.obtained && b) updatedFilter.obtained = undefined;
      else if (updatedFilter.obtained !== undefined && !updatedFilter.obtained && !b) updatedFilter.obtained = undefined;
      else updatedFilter.obtained = b;
    }

    setFilter(updatedFilter);
    props.onFilterChange(updatedFilter);
  }

  const formatQuestName = (name: string) => {
    const regex = /\[img:([a-z-]+)]/;
    const replacement = '<img src="/img/assets/$1.png" class="w-1/2 inline" alt="$1">';

    return name.replace(regex, replacement);
  }

  const extensionFilteredStats = (): {player: number, total: number, points: number} => {
    let player = 0;
    let total = 0;
    let points = 0;

    if (
      (filter.boosters == null || filter.boosters.length === 0) &&
      (filter.rarities == null || filter.rarities.length === 0) &&
      (filter.generation === 0 || filter.generation == null)
    ) {
      return {player: player, total: total, points: points};
    }

    const playerCards = props.playerCards.find(it => it.extension === (filter.extension != null ? filter.extension : ACTUAL_EXTENSION))!!
    pocketdex.extensions.find(it => it.id === filter.extension || ACTUAL_EXTENSION)!!
      .cards.forEach(card => {
        let requiredFilters = 0;
        let matchedFilters = 0;

        if (filter.boosters != null && filter.boosters.length > 0) requiredFilters++;
        if (filter.rarities != null && filter.rarities.length > 0) requiredFilters++;
        if (filter.generation != null && filter.generation !== 0) requiredFilters++;

        if (requiredFilters > 0) {
          if (card.boosters.some(booster => filter.boosters?.includes(booster))) matchedFilters++;
          if (filter.rarities?.includes(card.rarity)) matchedFilters++;
          if (card.generation === filter.generation) matchedFilters++;

          if (requiredFilters === matchedFilters) {
            if (!playerCards?.cards.includes(card.id)) {
              points += card.points;
            } else {
              player++;
            }

            total++;
          }
        }
      });

    return {player: player, total: total, points: points};
  }

  const FilteredStats = () => {
    const stats = extensionFilteredStats();

    if (stats.total === 0) {
      return null
    }

    return (
      <>
        <div className="mt-2">
          <p>Cartes filtrées : <code>{stats.player} / {stats.total}</code></p>
          <p>Points nécessaires : <code>{stats.points}</code></p>
        </div>
      </>
    )
  }

  if (!loaded) {
    return (<p>Loading...</p>)
  }

  return (
    <>
      <h2 className="text-lg font-semibold mt-5">Filtres</h2>

      <button
        className="font-semibold mb-2 text-blue-700 block"
        onClick={() => changeFilter("reset", "reset")}>
        Reset
      </button>

      <div className="my-3">
        Extension :
        <select
          name="extension"
          id="extension"
          className="ml-2"
          value={filter.extension || ACTUAL_EXTENSION}
          onChange={(event) => changeFilter(event.target.value, "extension")}
        >
          {
            pocketdex.extensions.map(extension => (
              <option value={extension.id}>{extension.name}</option>
            ))
          }
        </select>
      </div>

      <div className="grid grid-cols-3 gap-2 mb-3">
        {
          boosterFilters.map((f) => (
              <button className={filter.boosters?.includes(f) ? "selected-filter" : "filter"}
                      onClick={() => changeFilter(f, "booster")}
                      key={f}>
                <img src={"/img/assets/" + f.toLowerCase() + ".png"} alt={f}/>
              </button>
            ),
          )
        }
        {
          rarityFilters.map((f) => (
              <button className={filter.rarities?.includes(f) ? "selected-filter" : "filter"}
                      onClick={() => changeFilter(f, "rarity")}
                      key={f}>
                <img src={"/img/assets/" + f + ".png"} alt={"Rareté " + f}/>
              </button>
            ),
          )
        }
      </div>

      <div className="my-3">
        Génération :
        <select
          name="generation"
          id="generation"
          className="ml-2"
          value={filter.generation || 0}
          onChange={(event) => changeFilter(event.target.value, "generation")}
        >
          <option value="0">Toutes les cartes</option>
          <option value="1">Gen. 1</option>
          <option value="3">Gen. 3</option>
          <option value="5">Gen. 5</option>
          <option value="6">Gen. 6</option>
          <option value="7">Gen. 7</option>
          <option value="8">Gen. 8</option>
        </select>
      </div>

      <div className="block">
        <input type="checkbox"
               onChange={() => changeFilter("true", "obtained")}
               checked={filter.obtained === true}
               id="onlyObtained"
               className="mr-1"
        />
        <label htmlFor="onlyObtained">Cartes obtenues uniquement</label>
      </div>

      <div className="block">
        <input type="checkbox"
               onChange={() => changeFilter("", "obtained")}
               checked={filter.obtained === false}
               id="onlyNotObtained"
               className="mr-1"
        />
        <label htmlFor="onlyNotObtained">Cartes verrouillées uniquement</label>
      </div>

      <FilteredStats/>

      <div className="w-full mt-3">
        <h2 className="text-lg font-semibold mb-2">Quêtes secrètes</h2>
        <div className="grid grid-cols-2 gap-2 mb-3">
          {
            quests.map(quest => (
              <button
                className={(filter.quest === quest.name ? "selected-filter" : "filter") + " p-1 w-full"}
                onClick={() => changeFilter(quest.name + "|" + quest.extension, "quest")}
                dangerouslySetInnerHTML={{__html: formatQuestName(quest.name)}}
              />
            ))
          }
        </div>
      </div>
    </>
  );
};

export default Filters;